<script setup>
    // Initialize i18n
    const localePath = useLocalePath();

    const props = defineProps({
        // Button label
        label: {
            type: String,
            required: true,
        },
        // Link class
        class: {
            type: String,
            default: '',
        },
        // Button class
        buttonClass: {
            type: String,
            default: '',
        },
        // Button name
        name: {
            type: String,
            default: '',
        },
    });

    /**
     * Track the click on the button in GTM
     */
    const gtmBuyerButton = () => {
        // GTM Click - CTA - Buyers Registration
        dataLayer.push({
            event: 'generic_event',
            event_name: 'buyers_registration',
            button_id: props.name,
            button_name: props.name,
        });
    }
</script>

<template>
    <NuxtLink :to="localePath('register-buyer')" :class="props.class" @click="gtmBuyerButton">
        <PrimevueButton :label="props.label" severity="success" :class="props.buttonClass" />
    </NuxtLink>
</template>
